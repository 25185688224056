import { normalizeURL, normalizeSiteURL } from "utils"

import { SocialNetworks } from "@app/ui/social-networks"
import { Logo } from "ui/logo"
import { Container } from "ui/container"
import { useGlobalState } from "@app/contexts"

import styles from "./header.module.scss"

const Header = () => {
  const { logo, logoAlt, name: photographerName, phone, email, site, photographerSite } = useGlobalState()

  return (
    <Container>
      <header className={styles["header"]}>
        <div className={styles["logo"]}>
          {logo && (
            <Logo
              logo={logo}
              logoAlt={logoAlt}
              photographerName={photographerName}
              photographerSite={photographerSite}
              clickable={false}
            ></Logo>
          )}
        </div>
        <div className={styles["info"]}>
          {!logo && (
            <figure className={styles["avatar"]}>
              <figcaption className={styles["name"]}>{photographerName}</figcaption>
            </figure>
          )}

          <div className={styles["contacts"]}>
            {phone && (
              <a className={styles["contact"]} href={`tel:${phone}`}>
                {phone}
              </a>
            )}
            {email && (
              <a className={styles["contact"]} href={`mailto:${email}`}>
                {email}
              </a>
            )}
            {site && (
              <a className={styles["contact"]} href={normalizeURL(site)} target="_blank" rel="noopener noreferrer">
                {normalizeSiteURL(site)}
              </a>
            )}
          </div>

          <SocialNetworks />
        </div>
      </header>
    </Container>
  )
}
export { Header }
