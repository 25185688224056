import React from "react"

import { normalizeURL, normalizeSiteURL } from "@app/utils"
import { useGlobalState } from "@app/contexts"

import { Container } from "@app/ui/container"
import { SocialNetworks } from "@app/ui/social-networks"
import { Logo } from "@app/ui/logo"
import styles from "./footer.module.scss"

interface FooterProps {
  clickableLogo?: boolean
}

const Footer = ({ clickableLogo = false }: FooterProps) => {
  const { phone, email, site, logo, logoAlt, name: photographerName, site: photographerSite } = useGlobalState()

  return (
    <footer className={styles["footer"]}>
      <Container>
        <div className={styles["info"]}>
          <div className={styles["logo"]}>
            <Logo
              logo={logo}
              logoAlt={logoAlt}
              photographerName={photographerName}
              photographerSite={photographerSite}
              clickable={clickableLogo}
              location="footer"
              align="center"
            ></Logo>
          </div>
          <ul className={styles["contacts"]}>
            {phone && (
              <li className={styles["item"]}>
                <a className={styles["contact"]} href={`tel:${phone}`}>
                  {phone}
                </a>
              </li>
            )}
            {email && (
              <li className={styles["item"]}>
                <a className={styles["contact"]} href={`mailto:${email}`}>
                  {email}
                </a>
              </li>
            )}
            {site && (
              <li className={styles["item"]}>
                <a className={styles["contact"]} href={normalizeURL(site)} target="_blank" rel="noopener noreferrer">
                  {normalizeSiteURL(site)}
                </a>
              </li>
            )}
          </ul>

          <SocialNetworks />
        </div>
        <div className={styles["copyright"]}>
          <p className={styles["text"]}>
            ©{new Date().getFullYear()} {photographerName}
          </p>
          <p className={styles["text"]}>
            <a
              href={`https://vigbo.com/galleries?utm_source=vigbo.gallery&utm_medium=footer&utm_campaign=copyright_gallery_lp`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Gallery by Vigbo
            </a>
          </p>
        </div>
      </Container>
    </footer>
  )
}

export { Footer }
