import React from "react"

import Link from "next/link"
import cx from "clsx"

import { getLogoURLBySize } from "features/logo/utils"
import { normalizeURL } from "utils"

import styles from "./logo.module.scss"
import { GoogleImage } from "@app/features/google-image-service-status/components/google-image"
import { GoogleImageServiceStatusContext } from "@app/features/google-image-service-status/contexts"
import { useGoogleImageSrc } from "@app/features/google-image-service-status/hooks/use-google-image-src"

interface ILogo {
  logo?: string
  logoAlt?: string
  photographerName?: string
  photographerSite?: string
  clickable?: boolean
  location?: string
  align?: string
  className?: string
  onClick?: Function
}

function Logo({
  logo,
  logoAlt,
  photographerName,
  photographerSite,
  clickable = true,
  location,
  align,
  onClick,
  className,
}: ILogo) {
  const { isAvailable } = React.useContext(GoogleImageServiceStatusContext)
  const logoSrcSet = useGoogleImageSrc(getLogoURLBySize(logo, "m"), isAvailable)

  const logoLayout = (
    <GoogleImage
      src={`${getLogoURLBySize(logo, "s")}`}
      srcSet={logoSrcSet}
      className={cx(styles["image"], {
        [styles["in-footer"]]: location === "footer",
        [styles["center"]]: align === "center",
      })}
      alt={logoAlt}
    />
  )

  const _onClick = () => {
    if (typeof onClick === "function") onClick()
  }

  if (logo && clickable && photographerSite) {
    return (
      <figure className={cx(styles["logo"], className)}>
        <a
          onClick={() => _onClick()}
          href={normalizeURL(photographerSite)}
          rel="noopener noreferrer"
          target="_blank"
          className={styles["link"]}
        >
          {logoLayout}
        </a>
      </figure>
    )
  }

  if (logo && clickable && !photographerSite) {
    return (
      <figure className={cx(styles["logo"], className)}>
        <Link href="/">
          <a onClick={() => _onClick()} className={styles["link"]}>
            {logoLayout}
          </a>
        </Link>
      </figure>
    )
  }

  if (logo && !clickable) {
    return <figure className={cx(styles["logo"], className)}>{logoLayout}</figure>
  }

  if (!logo && photographerName && photographerSite) {
    return (
      <a
        href={normalizeURL(photographerSite)}
        rel="noopener noreferrer"
        onClick={() => _onClick()}
        target="_blank"
        className={cx(styles["plug"], className)}
      >
        <h1 className={styles["name"]}>{photographerName}</h1>
      </a>
    )
  }

  if (!logo && photographerName && !photographerSite) {
    return (
      <Link href="/">
        <a className={cx(styles["plug"], className)} onClick={() => _onClick()}>
          <h1 className={styles["name"]}>{photographerName}</h1>
        </a>
      </Link>
    )
  }

  return null
}

export { Logo }
