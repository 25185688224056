import Link from "next/link"
import cn from "clsx"
import { useInView } from "react-intersection-observer"

import { useImageLoading } from "@app/utils"
import { getCoverPositionInlineStyle, getCoverURLBySize } from "@app/features/cover/utils"
import { getFormattedDate } from "@app/features/gallery/utils"

import { PublicGalleryInterface } from "@app/features/gallery/types"

import { Lock } from "@app/ui/icons"
import { Tooltip } from "@app/ui/tooltip"

import styles from "./gallery-preview.module.scss"
import { IconWrapper } from "@app/features/theme/icon-wrapper"
import { GoogleImage } from "@app/features/google-image-service-status/components/google-image"

const GalleryPreview = ({ isPrivate, id, cover, settings, url, name, date }: PublicGalleryInterface) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
  })
  const { isImageLoaded, onImageLoad, imageRef } = useImageLoading()

  return (
    <div className={styles["root"]} ref={ref}>
      {isPrivate && (
        <div className={styles["status-icon"]}>
          <Tooltip text="Закрытая галерея">
            <div className={styles["locked"]}>
              <Lock width={12} height={13} stroke="#000" />
            </div>
          </Tooltip>
        </div>
      )}

      <div className={styles["thumbnail"]}>
        {cover && inView && (
          <GoogleImage
            src={`${getCoverURLBySize(cover, "l")}`}
            className={cn(styles["image"], isImageLoaded && styles["ready"])}
            alt=""
            style={getCoverPositionInlineStyle(settings.coverPosition)}
            onLoad={onImageLoad}
            ref={imageRef}
          />
        )}
        <Link href="/gallery/[slug]" as={`/gallery/${url}`}>
          <a className={styles["to-gallery"]}></a>
        </Link>
      </div>

      <Link href="/gallery/[slug]" as={`/gallery/${url}`}>
        <a className={styles["title"]}>{name}</a>
      </Link>

      <div className={styles["description"]}>
        {date && <span className={styles["date"]}>{getFormattedDate(date)}</span>}
      </div>
    </div>
  )
}

export { GalleryPreview }
