import { getCoverURLBySize } from "features/cover/utils"
import { getLogoURLBySize } from "features/logo/utils"
import {
  GALLERY_META_KEY,
  HOMEPAGE_META_KEY,
  NOT_FOUND_PAGE_META_KEY,
  PASSWORD_SCREEN_META_KEY,
  PRINT_INFO_PAGE_META_KEY,
  PHOTO_META_KEY,
  VIDEO_META_KEY,
} from "features/meta/constants"

import { useGlobalState } from "@app/contexts"

function getMeta({ key, settings, gallery, photo, file, domain, photographer, i18n }: any) {
  const userDomain = domain ? domain : settings?.domain || `${settings?.serviceDomain}.gallery.photo`

  const getLinkImageOriginal = function (photo_path, imageStorageOriginal) {
    return `${imageStorageOriginal}/${photo_path}`
  }

  const getImageSrc = function (photo_path) {
    const { imageStorageOriginal } = useGlobalState()
    const imageSrc = getLinkImageOriginal(photo_path[0], imageStorageOriginal)

    return imageSrc
  }

  const getDescription = function () {
    return gallery?.deleteAt
      ? settings?.name
        ? i18n.t("meta.galleryPage.description", { deleteAt: gallery?.deleteAt, name: settings?.name })
        : i18n.t("meta.galleryPage.descriptionShortDeleteAt", { deleteAt: gallery?.deleteAt })
      : settings?.name
        ? i18n.t("meta.galleryPage.descriptionShortName", { name: settings?.name })
        : ""
  }

  switch (key) {
    case HOMEPAGE_META_KEY:
      return {
        title: settings.name ? settings.name : "",
        description: settings.name ? settings.name : "",
        image: settings.logo ? getLogoURLBySize(settings.logo) : "",
        siteName: settings.name ? settings.name : "",
        url: `https://${userDomain}`,
      }

    case GALLERY_META_KEY:
      return {
        title: gallery.name,
        description: getDescription(),
        image: gallery.cover ? getCoverURLBySize(gallery.cover) : "",
        siteName: settings.name ? settings.name : "",
        url: `https://${userDomain}/gallery/${gallery.url}/`,
      }

    case PASSWORD_SCREEN_META_KEY:
      return {
        title: gallery.name,
        description: getDescription(),
        image: gallery.cover ? getCoverURLBySize(gallery.cover) : "",
        siteName: settings.name ? settings.name : "",
        url: `https://${userDomain}/gallery/${gallery.url}/`,
      }

    case PHOTO_META_KEY:
      const imageSrc = getImageSrc(photo?.photos)

      return {
        title: photo?.scene?.gallery?.name,
        description: photographer,
        image: imageSrc,
        url: `https://${userDomain}/photo/${photo.id}/`,
      }

    case VIDEO_META_KEY:
      return {
        title: file?.scene?.gallery?.name,
        description: photographer,
        url: `https://${userDomain}/video/${file.id}/`,
      }

    case PRINT_INFO_PAGE_META_KEY:
      return {
        title: "Печать ваших фото",
        description: "В галерее с вашими фото вы можете заказать печать от сервиса Vigbo",
        image: `https://${userDomain}/img/print-info/og.jpg`,
        siteName: settings.name ? settings.name : "",
        url: `https://${userDomain}/print-info`,
      }

    case NOT_FOUND_PAGE_META_KEY:
      return {
        title: "Страница не найдена",
        description: "Страница не найдена",
      }
  }
}

export { getMeta }
