import { sizes } from "features/cover/constants"

function getCoverURLBySize(data, size = sizes["l"]) {
  if (!data) {
    return null
  }

  if (data[size]) {
    return data[size]
  }

  return null
}

export { getCoverURLBySize }
