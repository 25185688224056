import { useSelectedTag } from "@app/features/tags/store/hooks"

import { isPublicGalleriesExist } from "@app/features/galleries/utils"

import { NoGalleries, GalleryPreview } from "@app/features/portfolio/components"
import { Container } from "@app/ui/container"

import styles from "./galleries.module.scss"

const Galleries = ({ galleries }) => {
  const { selectedTag } = useSelectedTag()

  if (!isPublicGalleriesExist(galleries)) {
    return <NoGalleries></NoGalleries>
  }

  return (
    <Container>
      <div className={styles["root"]}>
        {galleries.map((gallery) => {
          if (!selectedTag) {
            return <GalleryPreview key={gallery.id} {...gallery}></GalleryPreview>
          }

          if (gallery?.tags?.find((tag) => tag.id === selectedTag?.id)) {
            return <GalleryPreview key={gallery.id} {...gallery}></GalleryPreview>
          }
        })}
      </div>
    </Container>
  )
}

export { Galleries }
