type TThemes = {
  light: string
  dark: string
}

const THEMES: TThemes = {
  light: "light",
  dark: "dark",
}

export { THEMES }
