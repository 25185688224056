const HOMEPAGE_META_KEY: string = "HOMEPAGE_META_KEY"
const GALLERY_META_KEY: string = "GALLERY_META_KEY"
const PASSWORD_SCREEN_META_KEY: string = "PASSWORD_SCREEN_META_KEY"
const PHOTO_META_KEY: string = "PHOTO_META_KEY"
const VIDEO_META_KEY: string = "VIDEO_META_KEY"
const PRINT_INFO_PAGE_META_KEY: string = "PRINT_INFO_PAGE_META_KEY"
const NOT_FOUND_PAGE_META_KEY: string = "NOT_FOUND_PAGE_META_KEY"

export {
  HOMEPAGE_META_KEY,
  GALLERY_META_KEY,
  PASSWORD_SCREEN_META_KEY,
  PHOTO_META_KEY,
  VIDEO_META_KEY,
  PRINT_INFO_PAGE_META_KEY,
  NOT_FOUND_PAGE_META_KEY,
}
