import create from "zustand"

interface ISelectedTagStore {
  selectedTag: any
  setSelectedTag: (name: any) => void
  resetSelectedTag: () => void
}

const useSelectedTagStore = create<ISelectedTagStore>((set) => ({
  selectedTag: null,
  setSelectedTag: (name) => set(() => ({ selectedTag: name })),
  resetSelectedTag: () => set(() => ({ selectedTag: null })),
}))

export { useSelectedTagStore }
