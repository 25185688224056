import { ESocialNetworks, PHONES_SOCIAL_NETWORKS } from "../constants"

type TUserProfile = {
  [key in ESocialNetworks]?: string
} & {
  [key: string]: any
}

const getSocialNetworksList = (
  socialNetworksList: string[],
  userPofile: TUserProfile,
  icons: Record<ESocialNetworks, JSX.Element>
) => {
  const profileSocialNetworks = Object.keys(userPofile).filter(
    (key) => userPofile[key] && socialNetworksList.includes(key)
  )

  return profileSocialNetworks.map((key) => {
    const networkKey = key as ESocialNetworks
    return {
      value: userPofile[networkKey],
      icon: icons[networkKey],
      type: PHONES_SOCIAL_NETWORKS.includes(networkKey) ? key : "url",
    }
  })
}

export { getSocialNetworksList }
