import clsx from "clsx"

import { useGlobalState } from "@app/contexts"
import { IconWrapper } from "@app/features/theme/icon-wrapper"
import { Facebook, Insta, Telegram, Vimeo, Vk, WhatsApp, Youtube } from "@app/ui/icons"
import { normalizeURL } from "@app/utils"

import { getSocialNetworksList } from "./utils/get-social-networks-list"
import { ESocialNetworks, SOCIAL_NETWORKS } from "./constants"

import styles from "./social-networks.module.scss"

interface SocialNetworksProps {
  type?: ESocialNetworks
  className?: string
  excludeIcons?: ESocialNetworks[]
}

const socialIcons: Record<ESocialNetworks, JSX.Element> = {
  [ESocialNetworks.Instagram]: (
    <IconWrapper light={<Insta width={26} height={26} />} dark={<Insta width={26} height={26} fill="#fff" />} />
  ),
  [ESocialNetworks.Facebook]: (
    <IconWrapper light={<Facebook width={26} height={26} />} dark={<Facebook width={26} height={26} fill="#fff" />} />
  ),
  [ESocialNetworks.Vkontakte]: (
    <IconWrapper light={<Vk width={26} height={26} />} dark={<Vk width={26} height={26} fill="#fff" />} />
  ),
  [ESocialNetworks.Telegram]: (
    <IconWrapper light={<Telegram width={26} height={26} />} dark={<Telegram width={26} height={26} fill="#fff" />} />
  ),
  [ESocialNetworks.Vimeo]: (
    <IconWrapper light={<Vimeo width={26} height={26} />} dark={<Vimeo width={26} height={26} fill="#fff" />} />
  ),
  [ESocialNetworks.Youtube]: (
    <IconWrapper light={<Youtube width={26} height={26} />} dark={<Youtube width={26} height={26} fill="#fff" />} />
  ),
  [ESocialNetworks.WhatsApp]: (
    <IconWrapper
      light={<WhatsApp width={26} height={26} />}
      dark={<WhatsApp width={26} height={26} fill="#fff" stroke="#fff" />}
    />
  ),
}

const SocialNetworks = ({ className, excludeIcons = [] }: SocialNetworksProps) => {
  const userProfile = useGlobalState()

  const items = getSocialNetworksList(SOCIAL_NETWORKS, userProfile, socialIcons)

  const filteredItems = items.filter((item) => !excludeIcons.includes(item.type as ESocialNetworks))

  return (
    <ul className={clsx(styles["social-networks"], className)}>
      {filteredItems.map((item, index) => (
        <li key={index} className={styles["social-network"]}>
          <a
            className={styles["social-link"]}
            href={normalizeURL(item.value, item.type)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.icon}
          </a>
        </li>
      ))}
    </ul>
  )
}

export { SocialNetworks }
