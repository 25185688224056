import { useSelectedTagStore } from "features/tags/store"

const selectedTagSelector = (state) => state.selectedTag
const setSelectedTagSelector = (state) => state.setSelectedTag
const resetSelectedTagSelector = (state) => state.resetSelectedTag

function useSelectedTag() {
  const selectedTag = useSelectedTagStore(selectedTagSelector)
  const setSelectedTag = useSelectedTagStore(setSelectedTagSelector)
  const resetSelectedTag = useSelectedTagStore(resetSelectedTagSelector)

  return { selectedTag, setSelectedTag, resetSelectedTag }
}

export { useSelectedTag }
