import { sizes } from "features/logo/constants"

function getLogoURLBySize(data, size = sizes["m"]) {
  if (!data) {
    return null
  }

  if (data[size]) {
    return data[size]
  }

  return null
}

export { getLogoURLBySize }
