import Head from "next/head"
import PropTypes from "prop-types"

function Meta({ title, description, url, image, siteName }: any) {
  return (
    <Head>
      {/* Primary Meta Tags */}
      <title>{title}</title>
      <meta name="title" content={title}></meta>
      <meta name="description" content={description}></meta>

      {/* Open Graph / Facebook */}
      <meta property="og:title" content={title}></meta>
      <meta property="og:description" content={description}></meta>
      {image && <meta property="og:image" content={image}></meta>}
      {url && <meta property="og:url" content={url}></meta>}
      {siteName && <meta property="og:site_name" content={siteName}></meta>}

      {/* Twitter */}
      <meta property="twitter:title" content={title}></meta>
      {image && <meta property="twitter:image" content={image}></meta>}
      {image && <meta property="twitter:card" content="summary_large_image"></meta>}
      {url && <meta property="twitter:url" content={url}></meta>}
      <meta property="twitter:description" content={description}></meta>
    </Head>
  )
}

Meta.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  url: PropTypes.string,
  image: PropTypes.string,
}

export { Meta }
