import { parseCookies, setCookie } from "nookies"
import { NextPageContext } from "next"

import { COOKIES_MAX_AGE } from "@app/constants"

function getCookieWithGalleryPassword(domain: string, slug: string) {
  return `${domain}/gallery/${slug}__password`
}

function getGalleryPasswordFromCookies(domain: string, slug: string, ctx?: NextPageContext): string {
  const cookies = parseCookies(ctx)
  const galleryPassword = cookies[getCookieWithGalleryPassword(domain, slug)]

  if (galleryPassword) {
    return galleryPassword
  }

  return null
}

function setGalleryPasswordToCookies(domain: string, slug: string, password: string): void {
  setCookie(null, getCookieWithGalleryPassword(domain, slug), password, {
    maxAge: COOKIES_MAX_AGE,
    path: "/",
  })
}

export { getGalleryPasswordFromCookies, setGalleryPasswordToCookies }
