import PropTypes from "prop-types"
import cx from "clsx"

import styles from "./container.module.scss"

function Container({ children, isFullWidth = false, className }) {
  return (
    <div
      className={cx(
        styles["container"],
        {
          [styles["full-width"]]: isFullWidth,
        },
        className
      )}
    >
      {children}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  isFullWidth: PropTypes.bool,
}

export { Container }
