import React, { FunctionComponent } from "react"
import { THEMES } from "../constants"
import { hasGalleryContext, useGallery } from "@app/contexts/gallery"

type TThemeWrapperProps = {
  light: any
  dark: any
}

const IconWrapper: FunctionComponent<TThemeWrapperProps> = ({ light, dark }: TThemeWrapperProps) => {
  if (hasGalleryContext()) {
    const { gallery }: any = useGallery()
    return <>{gallery.theme?.handle === THEMES.light ? light : dark}</>
  }
  return <>{light}</>
}

export { IconWrapper }
