import format from "date-fns/format"
import parse from "date-fns/parse"
import ru from "date-fns/locale/ru"
import enUS from "date-fns/locale/en-US"
import uk from "date-fns/locale/uk"

import { LOCALE } from "@app/localization/constants"

const DATE_LOCALES = { [LOCALE.RU]: ru, [LOCALE.EN]: enUS, [LOCALE.UA]: uk }
const CLIENT_DATE_FORMAT = "dd MMMM yyyy"
const SERVER_DATE_FORMAT = "dd.MM.yyyy"

function getFormattedDate(date: any, locale = LOCALE.DEFAULT) {
  if (!date) return null

  return format(
    parse(date, SERVER_DATE_FORMAT, new Date(), {
      locale: DATE_LOCALES[locale],
    }),
    CLIENT_DATE_FORMAT,
    {
      locale: DATE_LOCALES[locale],
    }
  )
}

export { getFormattedDate }
