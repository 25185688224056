function isPublicGalleriesExist(galleries) {
  if (!galleries) {
    return false
  }

  if (galleries?.length < 1) {
    return false
  }

  if (galleries.some((gallery) => gallery.settings.hasOwnProperty("isPublic") && gallery.settings.isPublic)) {
    return true
  }

  return false
}

export { isPublicGalleriesExist }
