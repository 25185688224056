import React from "react"
import cx from "clsx"

import styles from "./tooltip.module.scss"

interface TooltipProps {
  text: string
  position?: "up" | "down" | "left" | "right" | "up-left" | "up-right" | "down-left" | "down-right"
  children: React.ReactNode | JSX.Element
  className?: string
}

const Tooltip = ({ text, position = "down", children, className }: TooltipProps) => {
  return (
    <div aria-label={text} data-tooltip-position={position} className={cx(styles["root"], className)}>
      {children}
    </div>
  )
}

export { Tooltip }
