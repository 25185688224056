export enum ESocialNetworks {
  Instagram = "instagram",
  Facebook = "facebook",
  Telegram = "telegram",
  Vimeo = "vimeo",
  Vkontakte = "vkontakte",
  WhatsApp = "whatsapp",
  Youtube = "youtube",
}

export const SOCIAL_NETWORKS = [
  ESocialNetworks.Instagram,
  ESocialNetworks.Facebook,
  ESocialNetworks.Telegram,
  ESocialNetworks.Vimeo,
  ESocialNetworks.Vkontakte,
  ESocialNetworks.WhatsApp,
  ESocialNetworks.Youtube,
]

export const PHONES_SOCIAL_NETWORKS = [ESocialNetworks.Telegram, ESocialNetworks.WhatsApp]
